import {ISettings} from './interfaces/ISettings'
import {tr} from "date-fns/locale";

type languages = 'en' | 'fr'

const local_settings: ISettings<languages> = {
    API_DEV_URL: 'https://goodwin.dev.bet4skill.com',
    API_PROD_URL: 'https://api.goodwin365.net',

    MIRRORS: {},

    OTHER: {
        CURRENCY: {
            TITLE: 'TND',
            KEY: 'TND'
        },
        LANGUAGES: {
            en: {
                VISIBLE: true,
                DEFAULT: false,
                DIRECTION: 'rtl',
                TITLE: 'English',
                KEY: 'en'
            },
            fr: {
                VISIBLE: true,
                DEFAULT: true,
                DIRECTION: 'rtl',
                TITLE: 'French',
                KEY: 'fr'
            },
        },
        VARIANT: 'goodwin',
        HEADER: [
            'HOME',
            'VIRTUAL_GAMES',
            'GAMES',
            'TV_GAMES',
            'EVOLUTION',
            'AMATIC',
            'FAVORITE_GAMES',
            'PROMOTIONS',
            'HELP',
            'HALL_OF_FAME'
        ],
        LOGIN_LOGO: 'goodwin',
        FOOTER: {
            MENU: ['HOME', 'GAMES', 'TV_GAMES'],
            PAYMENTS: ['runpay'],
        },
        MOBILE_MENU: {
            HOME: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            SPORT: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            ESPORT: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            LIVE: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 2,
                SPAN: 1
            },
            VIRTUAL_GAMES: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 1,
                SPAN: 1
            },
            GAMES: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 1,
                SPAN: 2
            },
            TV_GAMES: {
                VISIBLE: true,
                ROW: 1,
                COLUMN: 3,
                SPAN: 1
            },
            EVOLUTION_MOBILE: {
                VISIBLE: true,
                ROW: 2,
                COLUMN: 2,
                SPAN: 2
            },
            AMATIC: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            FAVORITE_GAMES: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            PROMOTIONS: {
                VISIBLE: true,
                ROW: 3,
                COLUMN: 3,
                SPAN: 1
            },
            HORSES: {
                VISIBLE: false,
                ROW: 1,
                COLUMN: 1,
                SPAN: 1
            },
            TURBO_GAMES: {
                VISIBLE: false,
                ROW: 2,
                COLUMN: 3,
                SPAN: 1
            },
            PRAGMATIC: {
                VISIBLE: true,
                ROW: 3,
                COLUMN: 1,
                SPAN: 2
            },
            HALL_OF_FAME: {
                VISIBLE: true,
                ROW: 4,
                COLUMN: 1,
                SPAN: 1
            }
        }

    },

    SEO: {
        TITLE: 'Goodwin365.net',
        SITE_NAME: 'Goodwin365',
        LOGO_AND_FAVICON: 'goodwin',
        DESCRIPTION: 'Play with goodwin365',
        KEYWORDS: ['Goodwin365', 'Goodwin', '365'],
        SOCIAL_LINKS: {
            FACEBOOK: 'https://www.facebook.com/goodwin365.net/'
        }
    },

    THEME: {
        VARIANT: 'goodwin',
        DEFAULT: 'light',
        TOGGLE: true
    },

    ACCESS: {
        SPORT: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        LIVE_SPORT: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        LOGIN: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        REGISTER: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        RESET_PASSWORD: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        RECOVERY_PASSWORD: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        CONFIRM_EMAIL: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        GAME: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        GAMES: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        TV_GAMES: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        HORSES: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE_PERSONAL_DATA: {
            VISIBLE: false,
            PERMISSIONS: {
                ID: true,
                USERNAME: true,
                PHONE: true,
                EMAIL: true,
                NAME: true,
                SURNAME: true,
                ADDRESS: true,
                ID_CARD: true,
                BALANCE: false,
                CURRENCY: false,
                FIRST_NAME: false,
                SECOND_NAME: false,
                LAST_NAME: false,
                BIRTHDAY: false,
                GET_FULL_DATA: true,
                UPDATE_USER_INFO: true
            }
        },
        PROFILE_DEPOSIT: {
            VISIBLE: false,
            PERMISSIONS: {
                RUNPAY: false,
                VOUCHER: false,
                MAKSI_PAPARA: false,
                MAKSI_CRYPTO: false,
                MAKSI_HAYHAY: false,
                MAKSI_PAYFIX: false,
                MAKSI_CREDIT_CARD: false,
                MAKSI_PEP: false,
                MAKSI_BANK: false
            }
        },
        PROFILE_USER_DOCUMENT: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE_WITHDRAWAL: {
            VISIBLE: false,
            PERMISSIONS: {
                RUNPAY: true,
                BANK: true,
                MAKSI_PAPARA: false,
                MAKSI_CRYPTO: false,
                MAKSI_HAYHAY: false,
                MAKSI_PAYFIX: false,
                MAKSI_PEP: false,
                MAKSI_BANK: false
            }
        },
        PROFILE_OPERATION_HISTORY: {
            VISIBLE: false,
            PERMISSIONS: {
                TYPE: true,
                DATE: true,
                AMOUNT: true,
                STATUS: true,
                ID: true,
                DECLAINE_REASON: true,
            }
        },
        PROFILE_BET_HISTORY: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE_CASHBACK_HISTORY: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROFILE_SETTINGS: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROMOTIONS: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        PROMOTION: {
            VISIBLE: false,
            PERMISSIONS: {}
        },
        HALL_OF_FAME: {
            VISIBLE: false,
            PERMISSIONS: {},
        },
    },

    SERVICES_DEV: {
        BETBY: {
            OPERATOR_ID: '2136455562131546112',
            BRAND_ID: '2143079564828610560',
            EXTERNAL_API: 'https://external-api.invisiblesport.com/api/v1/external_api/',
            BETSLIP_URL: 'https://ui.invisiblesport.com/bt-renderer.min.js',
            THEME_NAME: 'goodwin-365'
        },
        TAWK: {
            PROPERTY_ID: '1g36hg3rc',
            ID: '628253fbb0d10b6f3e726d55'
        },
        YM: {
            KEY: '89462512'
        }
    },
    SERVICES_PROD: {
        BETBY: {
            OPERATOR_ID: '2139066602350579712',
            BRAND_ID: '2150957722907062272',
            EXTERNAL_API: 'https://gw9yca5f.sptenv.com/api/v1/external_api/',
            BETSLIP_URL: 'https://goodwin365.sptpub.com/bt-renderer.min.js',
            THEME_NAME: 'goodwin-365'
        },
        TAWK: {
            PROPERTY_ID: '1g36hg3rc',
            ID: '628253fbb0d10b6f3e726d55'
        },
        YM: {
            KEY: '89462512'
        },
        FB: {
            META_PIXEL: '403455254405665',
            META_CONTENT: '0jz314yv0lkx98jr3qoapwcrwdf33u'
        },
        GA: {
            ID: 'G-VXZ90P1HZS'
        }
    }

}

export default local_settings
